<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                :src="require('@/assets/images/pages/ancestry-login.jpg')"
                alt="login"
                class="mx-auto authorize-logo"
              />
            </div>

            <div
              class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg"
            >
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title mb-8">
                  <h4>Login</h4>
                  <div class="otherlink-box">
                    <a
                      :href="poligonVersionLink"
                      class="exchange-link text-primary mb-3"
                    >
                      Polygon Version
                      <feather-icon icon="ArrowRightIcon"></feather-icon>
                    </a>
                  </div>
                </div>

                <TronWebComponent />

                <section class="tron-web-message" v-if="step === 1">
                  <span v-if="getTronAccessStatus === false">
                    Please Install Tron Wallet From
                    <a :href="tronWebInstallAddress">Here</a>.
                  </span>
                  <span v-if="getLoginStatus === false">
                    Please Login into your Tron Wallet account!
                  </span>
                  <div
                    v-if="getTronConnectionStatus === true"
                    class="text-center"
                  >
                    <p class="mb-base text-danger">
                      * For temporary use *
                      <br />
                      * This version will be deprecated after migration time *
                    </p>
                    <p class="mb-base">
                      Successfully connected to your Wallet
                    </p>
                    <p class="wallet-address">
                      {{ getDefaultAddress.base58 }}
                    </p>
                    <form @submit.prevent="get_token" class="twofa">
                      <vs-input
                        placeholder="2fa code"
                        v-model="code"
                        class="w-full"
                      />
                      <div class="actions">
                        <vs-button
                          color="primary"
                          type="filled"
                          size="small"
                          @click="get_token"
                          >Login</vs-button
                        >

                        <vs-button
                          color="danger"
                          type="border"
                          size="small "
                          @click="get_token"
                          >Dont Have</vs-button
                        >
                      </div>
                    </form>
                  </div>
                </section>

                <section class="qr-code-box" v-if="step === 2">
                  <img :src="qrCode" alt="qrCode" />
                  <div class="twofa-key pt-5">
                    <p>{{ user.two_fa_key }}</p>
                    <feather-icon
                      icon="CopyIcon"
                      class="text-primary"
                      @click="copy(user.two_fa_key)"
                    ></feather-icon>
                  </div>
                  <form @submit.prevent="save_twofa" class="twofa">
                    <vs-input
                      placeholder="2fa code"
                      v-model="twoFaCode.code"
                      class="w-full"
                    />
                    <div class="actions">
                      <vs-button
                        color="primary"
                        type="filled"
                        size="small"
                        @click="save_twofa"
                        >Login</vs-button
                      >
                    </div>
                  </form>
                </section>
                <vs-divider color="primary" class="google-divider"
                  ><router-link :to="{ name: 'google-help' }"
                    >Google Authentication Help</router-link
                  ></vs-divider
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { callContractMixin } from "@/mixins/callCotractMixin";
import TronWebComponent from "@/components/tron-web/TronWebComponent.vue";

export default {
  components: {
    TronWebComponent
  },
  data() {
    return {
      step: 1,
      qrCode: "",
      tronWebInstallAddress:
        "https://chrome.google.com/webstore/detail/tronlink%EF%BC%88%E6%B3%A2%E5%AE%9D%E9%92%B1%E5%8C%85%EF%BC%89/ibnejdfjmmkpcnlpebklmnkoeoihofec",

      //
      totalUsdt: "",
      totalEcs: "",

      // Step 1
      token: "",
      code: "",

      // Step 2
      twoFaCode: {
        code: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      getTronConnectionStatus: "tronBot/getTronConnectionStatus",
      getTronAccessStatus: "tronBot/getTronAccessStatus",
      getLoginStatus: "tronBot/getLoginStatus",
      getDefaultAddress: "tronBot/getDefaultAddress",
      getTronWebObject: "tronBot/getTronWebObject",
      //
      contractInfo: "contract/contractInfo",
      //
      dashboard: "auth/dashboard",
      //
      user: "auth/user",
      ECS_Approved: "modulePayment/ECS_Approved",
      USDT_Approved: "modulePayment/USDT_Approved"
    }),

    poligonVersionLink() {
      return process.env.VUE_APP_POLYGON_VERSION_LINK;
    }
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getDashboardData: "auth/getDashboardData",
      getToken: "auth/getToken",
      saveTwoFa: "auth/saveTwoFa",
      getBaseInfo: "contract/getBaseInfo",
      pay: "modulePayment/pay"
    }),
    //

    // Copy To Clipboard
    copy(val) {
      let tempInput = document.createElement("input");
      tempInput.value = val;
      document.body.prepend(tempInput);
      tempInput.select();
      document.execCommand("copy");
      this.$vs.notify({
        title: "Copy To Clipboard",
        text: "Key Copied To Clipboard",
        iconPack: "feather",
        icon: "icon-copy",
        color: "success",
        position: "top-right"
      });
      document.body.removeChild(tempInput);
    },

    // Redirect To Dashboard
    redirectDashboard() {
      this.$vs.notify({
        title: "You Are Loged In",
        text: "Redirecting To Dashboard Page",
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right"
      });

      setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },
    //

    // Pay USDT
    async payUSDT() {
      let usdt = this.getTronWebObject.toSun(
        this.USDT_Approved ? +this.USDT_Approved : this.totalUsdt
      );
      await this.callContract(
        this.contractInfo.usdtContractAddress,
        "allowance",
        [this.getDefaultAddress.base58, this.contractInfo.contractAddress],
        res => {
          if (res) {
            if (res.remaining >= +usdt) {
              this.payECS();
            } else {
              this.callContract(
                this.contractInfo.usdtContractAddress,
                "approve",
                [this.contractInfo.contractAddress, usdt],
                res => {
                  if (res) {
                    this.payECS();
                  } else {
                    this.$vs.loading.close();
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    // Pay ECS
    async payECS() {
      let payParam = {
        appId: 1,
        userId: this.user.id,
        orderId: 0,
        usdt: this.USDT_Approved ? +this.USDT_Approved : this.totalUsdt,
        token: this.ECS_Approved ? +this.ECS_Approved : this.totalEcs
      };

      let ecs = this.getTronWebObject.toSun(
        this.ECS_Approved ? +this.ECS_Approved : this.totalEcs
      );

      //
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "allowance",
        [this.getDefaultAddress.base58, this.contractInfo.contractAddress],
        res => {
          if (res) {
            if (res >= +ecs) {
              this.pay(payParam).then(res => {
                if (res) {
                  this.redirectDashboard();
                  this.$vs.loading.close();
                }
              });
            } else {
              this.callContract(
                this.contractInfo.ecsContractAddress,
                "approve",
                [this.contractInfo.contractAddress, ecs],
                res => {
                  if (res) {
                    this.pay(payParam).then(res => {
                      if (res) {
                        this.redirectDashboard();
                        this.$vs.loading.close();
                      } else {
                        this.$vs.loading.close();
                      }
                    });
                  }
                },
                true
              );
            }
          }
        },
        false
      );
    },
    //

    // Pay Request
    async payRequest() {
      let minUsdt = +this.contractInfo.minUsdtAmount;
      let feeUsdt = +this.contractInfo.usdtFeeAmount;
      let myUsdtInvestment = +this.dashboard.myUsdtInvestment;
      this.totalUsdt = minUsdt + feeUsdt;

      let minEcs = +this.contractInfo.minEcsAmount;
      let feeEcs = +this.contractInfo.ecsFeeAmount;
      let myEcsInvestment = +this.dashboard.myUsdtInvestment;
      this.totalEcs = minEcs + feeEcs;

      //
      if (myUsdtInvestment < minUsdt && myEcsInvestment <= minEcs) {
        try {
          this.payUSDT();
        } catch (error) {
          return false;
        }
      } else {
        this.$vs.loading.close();
        this.redirectDashboard();
      }
    },
    //

    // Get Token
    async get_token() {
      // Start Loading
      this.$vs.loading();
      //
      await this.getContractToken();

      this.getToken({
        wallet: this.getDefaultAddress.base58,
        app_id: 1,
        token: this.token,
        code: this.code
      }).then(res => {
        //
        if (res) {
          // Close Loading
          this.$vs.loading.close();

          if (typeof res === "boolean") {
            this.redirectDashboard();
            // this.getDashboardData().then(res => {
            //   if (res === true) {
            //     this.payRequest();
            //   }
            // });
          } else {
            this.qrCode = res;
            this.step = 2;
          }
        } else {
          // Close Loading
          this.$vs.loading.close();
        }
      });
    },
    //

    // Save 2fa
    async save_twofa() {
      await this.getContractToken();
      // Start Loading
      this.$vs.loading();
      //

      this.saveTwoFa(this.twoFaCode).then(res => {
        //
        if (res) {
          this.redirectDashboard();
          // Get Dashboard Data
          // this.getDashboardData().then(res => {
          //   if (res === true) {
          //     this.payRequest();
          //   }
          // });
        } else {
          // Close Loading
          this.$vs.loading.close();
        }
      });
    },
    //

    // Get Contract Token
    async getContractToken() {
      await this.callContract(
        this.contractInfo.contractAddress,
        "getToken",
        [],
        res => {
          if (res) {
            this.token = res;
          }
        },
        false
      );
    }
  },
  async mounted() {
    // Get Base Info
    await this.getBaseInfo();
    //

    if (this.getLoginStatus) {
      this.getContractToken();
    }
  },

  watch: {
    getLoginStatus() {
      if (this.getLoginStatus) {
        this.getContractToken();
      }
    }
  },
  mixins: [callContractMixin]
};
</script>

<style lang="scss">
@import "./login.scss";
</style>
